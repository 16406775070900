import React, { useEffect, useState } from "react"
import Helmet from "react-helmet"
import { useQueryParam, StringParam } from "use-query-params"
import validateEmail from "../../utils/validateEmail"
import AOS from "aos"

import episodes from "../../data/episodes.json"
import ebooks from "../../data/ebooks.json"
import "./episodepage.scss"
import "aos/dist/aos.css"

const EpisodePage = () => {
  const [id] = useQueryParam("id", StringParam)
  const [episode, setEpisode] = useState({
    id: "",
    image: "",
    name: "",
    description: "",
    date: "",
    duration: "",
    long_description: [],
    ebook_id: "",
  })
  const [email, setEmail] = useState("")
  const [emailIsValid, setEmailIsValid] = useState(false)

  function handleEmailChange(event) {
    const { value } = event.target
    setEmail(value)
    setEmailIsValid(validateEmail(value))
  }

  function handleSubmitForm(event) {
    event.preventDefault()

    if (!emailIsValid) {
      event.stopPropagation()

      return
    }

    setTimeout(() => {
      setEmail("")
      setEmailIsValid(false)
    }, 2000)

    alert("Cadastrado")
    const ebook = ebooks.find(ebook =>
      ebook.id === episode.ebook_id ? ebook : 0
    )
    window.open(ebook.link)
  }

  useEffect(() => {
    setEpisode(
      episodes.find(episode_element => {
        if (episode_element.id === id) return episode_element
        return 0
      })
    )
  }, [id])

  useEffect(() => {
    AOS.init({
      once: true,
      duration: 1000,
      easing: "ease-in-out",
      offset: -20,
    })
  })

  return (
    <div id="episode-page">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{episode.name} | FABWORK</title>
        <link rel="canonical" href={`http://fab.work/episode?id=${id}`} />
      </Helmet>
      <iframe
        src={`https://open.spotify.com/embed-podcast/episode/${episode.id}`}
        width="100%"
        height="232"
        frameBorder="0"
        allowtransparency="true"
        allow="encrypted-media"
        title="podcast-episodio"
      ></iframe>
      <div className="content-and-form">
        <div className="episode-content">
          <h1 data-aos="fade-right" className="episode-title">
            {episode.name}
          </h1>
          <p data-aos="fade-right" data-aos-delay="50" className="description">
            {episode.description}
          </p>
          <div className="long-description">
            {episode.long_description.map((paragraph, index) => (
              <p
                data-aos="fade-right"
                data-aos-delay={50 + 20 * ((index % 3) + 1)}
                key={paragraph}
              >
                {paragraph}
              </p>
            ))}
          </div>
        </div>
        {episode.ebook_id !== "" ? (
          <form
            id={`mini-ebook-${episode.ebook_id}`}
            className="episode-form"
            onSubmit={handleSubmitForm}
            data-aos="fade-right"
          >
            <h2>Acesse o e-book deste episódio</h2>
            <input
              type="email"
              name="email"
              id="email"
              placeholder="Email"
              value={email}
              onChange={handleEmailChange}
            />
            <button
              disabled={!emailIsValid}
              className={`btn ${!emailIsValid ? "disabled" : "abled"}`}
              type="submit"
            >
              baixar e-book
            </button>
          </form>
        ) : null}
      </div>
    </div>
  )
}

export default EpisodePage
