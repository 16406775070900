import React from "react"
import RDStationConection from "../utils/RDStationConnection"

import Header from "../components/Header/Header"
import EpisodePage from "../components/EpisodePage/EpisodePage"
import Footer from "../components/Footer/Footer"
import NeoronWidget from "../utils/NeoronWidget"

export default function Episode() {
  React.useEffect(() => {
    RDStationConection.init()
    NeoronWidget.init()
  }, [])

  return (
    <div>
      <Header />
      <EpisodePage />
      <Footer />
    </div>
  )
}
